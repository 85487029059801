<template>
  <div>
    <!-- Fixed navbar -->
    <header class="nav">
      <img src="@/assets/img.png">
      <input type="checkbox" id="toggle"/>
      <nav>
        <label for="toggle" class="toggle" data-open="Menu" data-close="Close" onclick></label>
        <ul class="menu">
          <li><a href="#">Back</a></li>
        </ul>
      </nav>
    </header>

    <div v-if="posts.length !== 0">
      <div class="gallery">
        <ul>
          <li v-for="post in posts.slice().reverse()" :key="post.trinder_id">
            <img :src="post.image_link" :alt="post.post_contents" loading="lazy">
          </li>
          <li></li>
        </ul>
      </div>
    </div>

    <div class="show-more">
      <button @click="showMore">Show more</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    let amount = 15
    let posts = []
    return {
      posts, amount
    }
  },

  async mounted() {
    await this.displayPosts()
  },

  methods: {
    async showMore() {
      this.amount += 10;
      await this.displayPosts()
    },

    async displayPosts() {
      let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      await fetch('https://api.trindertcd.org/open/archive/last/' + this.amount, requestOptions)
          .then(res => res.json())
          .then(data =>
            this.posts = data.filter(function (el) {
              return el != null;
            })
          )
          .catch(err => console.error(err.message))
    }
  }


}
</script>

<style lang="scss">
@import './src/assets/sass/nav.scss';
@import './src/assets/sass/img-lib.scss';
@import '/src/assets/sass/button.scss';

body {
  background-color: #eeee;
}

</style>
